import React, { useEffect, useState } from "react";
import SEO from "../shared/seo";
import { ReferenciaContenido } from "../interfaces/ReferenciaContenido";
import { getInsideInfo } from "../services/contentful";
import { getEntryDeliveryApi } from "../shared/services/CMS.js";
import HeadboardTrees from "../components/organisms/headboard-trees/headboardTrees";
import Layout from "../components/organisms/layout/layout";
import LibraryComponents from "../components/templates/library-components/libraryComponents";

const TemplateFive = (props: any) => {
    const [pageContext, setPageContext] = useState<any>({})
    const [footerImg, setFooterImg] = useState<any>('')

    useEffect(() => { 
        const getPageContext = async () => { 
           let dataCont: any = await getEntryDeliveryApi(props.pageContext.contentful_id)
           dataCont.referenciaCabecera = await getInsideInfo(dataCont.referenciaCabecera)
           dataCont.referenciaContenido = await getInsideInfo(dataCont.referenciaContenido)
           dataCont.referenciaCabecera.migaDePan = props.pageContext.referenciaCabecera.migaDePan
           setPageContext(dataCont)
           setFooterImg(dataCont.referenciaCabecera.imagenesArboles[4].file.url)
        } 
        getPageContext()
    }, [])
    
    const pageSlug = props.location.pathname

    return (
        <Layout hideHeaderDesktop hideHeaderMobile footerImg={footerImg} hideBurger hideSidebar glassHeader footerWhite>
            <SEO title={props.pageContext.metaTitulo || "Árboles que cuentan historias"} 
                description={ props.pageContext.metaDescripcion && props.pageContext.metaDescripcion.json.content[0].content[0].value }
                noIndex={props.pageContext.noIndex}
                image={props.pageContext.referenciaCabecera.imagenHeader.file.url}
                slug={pageSlug}
                fbId={process.env.FACEBOOK_APP_ID_HOME}
            />
            {
                pageContext.referenciaCabecera
                ? <HeadboardTrees {...pageContext.referenciaCabecera} />
                : <></>
            }
            {
                pageContext.referenciaContenido
                ? pageContext.referenciaContenido.map(
                    ( value: ReferenciaContenido, index: number) => (
                        <LibraryComponents
                            key={index}
                            content={value}
                            categoryName={pageContext.nombreCategoria}
                        />
                    )
                )
                : <></>
            }
        </Layout>
    );
};

export default TemplateFive;
